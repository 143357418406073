import React from "react";
import { connect } from "react-redux";

import UIkit from "uikit";

import useLoader from "~/components/commonHooks/useLoader";

import { DoctorUserLogin, DoctorForgotPassword, DoctorSetInputText, DoctorSetValidationError } from "~/actions/action-doctor";

import { ProjectUrl } from "~/env";

const DoctorLogin = (props) => {
	// showing loader on login
	const [loader, startLoader, stopLoader] = useLoader(false);
	// disabling forgot password button
	const [loader_forgot_button, startLoaderForgotButton, stopLoaderForgotButton] = useLoader(false);

	const handleInputTextChange = (e) => {
		props.Doctor_Set_Input_Text(e.target.name, e.target.value);
	};

	const handleForgotPassword = () => {
		startLoaderForgotButton();
		props
			.Doctor_Forgot_Password()
			.then(() => {
				stopLoaderForgotButton();
				UIkit.modal("#modalForgotPwd").show();
			})
			.catch(() => stopLoaderForgotButton());
	};

	// handle enter press on password field
	const handleEnterLogin = (e) => {
		if (e.keyCode === 13) {
			handleLogin();
		}
	};

	const onFocusRemoveError = (e) => {
		if (!!props.errors[e.target.name]) {
			let errors = { ...props.errors };
			delete errors[e.target.name];
			props.Doctor_Set_Validation_Error(errors);
		}
	};

	const handleLogin = () => {
		startLoader();
		props
			.Doctor_User_Login()
			.then(() => {
				stopLoader();
				window.location = ProjectUrl + "/analytics/dashboard";
			})
			.catch(() => stopLoader());
	};

	return (
		<div className="doctorLogin">
			<button onClick={handleForgotPassword} className="uk-button uk-button-link forgotPasswordBt" disabled={loader_forgot_button}>
				Forgot Password?
			</button>

			<div className="uk-grid-column-collapse uk-flex-right@s uk-grid">
				<div className="uk-width-auto@s  loginFormInput">
					<span className="loginFormInputError">{props.errors.email}</span>
					<div className="uk-width-1-1" style={{ position: "relative" }}>
						<i className="uk-form-icon uk-icon" uk-icon="icon: user;ratio:0.8"></i>
						<input
							value={props.email}
							onChange={handleInputTextChange}
							onFocus={onFocusRemoveError}
							name="email"
							type="email"
							className={props.errors.email ? "uk-input uk-form-danger" : "uk-input"}
							placeholder="User Id"
							required
						/>
					</div>
				</div>
				<div className="uk-width-auto@s loginFormInput">
					<span className="loginFormInputError">{props.errors.password}</span>
					<div className="uk-width-1-1" style={{ position: "relative" }}>
						<i className="uk-form-icon" uk-icon="icon: lock;ratio:0.8"></i>
						<input
							value={props.password}
							onChange={handleInputTextChange}
							onFocus={onFocusRemoveError}
							onKeyUp={handleEnterLogin}
							name="password"
							type="password"
							className={props.errors.password ? "uk-input uk-form-danger" : "uk-input"}
							placeholder="Password"
							required
						/>
					</div>
				</div>

				<div className=" uk-width-1-1 uk-width-auto@s">
					<button onClick={handleLogin} disabled={loader} className="uk-button uk-button-secondary loginBt uk-width-1-1 uk-width-auto@s">
						{loader ? <span uk-spinner="ratio:0.5" /> : "Login"}
					</button>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	email: state.doctor_store.email,
	errors: state.doctor_store.errors,
	password: state.doctor_store.password,
});
const mapDispatchToProps = (dispatch) => ({
	Doctor_User_Login: () => dispatch(DoctorUserLogin()),
	Doctor_Forgot_Password: () => dispatch(DoctorForgotPassword()),
	Doctor_Set_Validation_Error: (errors) => dispatch(DoctorSetValidationError(errors)),
	Doctor_Set_Input_Text: (input_name, input_value) => dispatch(DoctorSetInputText(input_name, input_value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DoctorLogin);
