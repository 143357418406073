import React from "react";

import UIkit from "uikit";

import DoctorLogin from "~/components/login/doctor-login";

import { ProjectUrl } from "~/env";

const LoginContent = () => {
	return (
		<>
			<section className="ln-mainBanner">
				<div className="uk-container">
					<div className="uk-flex-middle uk-grid-column-collapse" uk-grid="true">
						<div className="uk-width-1-2@s uk-visible@s">
							<img src="images/login/banner.png" />
						</div>
						<div className="uk-width-1-2@s ln-bannerContent">
							<div>
								<h2>Expand your horizon</h2>
								<h1>Move beyond your physical clinic</h1>
								<h3>Consult millions of patients online from anywhere at a tap of a button.</h3>
								<a href="/register" className="uk-button uk-margin-top uk-button-secondary uk-width-1-3@s">
									Sign Up
								</a>
							</div>
						</div>
					</div>
					{/* <div className="uk-grid">
						<div className="uk-width-2-3@s">
							<div className="ln-bannerContent">
								<div>
									<h1>
										Don't limit your practice
										<br /> Experience the virtual process.
									</h1>
									<h3>Consult over millions of patients online and grow your reputation across the nation.</h3>
									<a href="/register" className="uk-button uk-margin-top uk-button-secondary">
										Sign Up
									</a>
								</div>
							</div>
						</div>
						<div className="uk-width-1-3@s uk-visible@s">
							<img src="images/login/banner.png" />
						</div>
					</div> */}
				</div>
			</section>

			<section className="ln-featured">
				<div className="uk-container">
					<div className="ln-sectionTitle">
						<h2>Why Healthmug?</h2>
						<h3>Work when it's convenient for you. Get online and grow your professional network</h3>
					</div>
					<div
						className="uk-child-width-1-2 uk-child-width-1-4@s uk-grid"
						uk-scrollspy="cls: uk-animation-slide-bottom uk-animation-fade; target: .ln-featuredBox; delay: 300; repeat: false"
					>
						<div>
							<div className="ln-featuredBox">
								<img src="images/login/ft-consult-anywhere.png" />
								<h5>Consult Patients Anywhere</h5>
								<p>Provide consultations in 1/4th the time compared to your physical practice.</p>
							</div>
						</div>
						<div>
							<div className="ln-featuredBox">
								<img src="images/login/ft-earning.png" />
								<h5>Earn Without Expense</h5>
								<p>You earn practically without investing your capital in physical space.</p>
							</div>
						</div>
						<div>
							<div className="ln-featuredBox">
								<img src="images/login/ft-doctor-community.png" />
								<h5>Exclusive Doctors Community</h5>
								<p>Connect with like-minded specialists and build your network.</p>
							</div>
						</div>

						<div>
							<div className="ln-featuredBox">
								<img src="images/login/ft-online-presence.png" />
								<h5>Build Online Presence</h5>
								<p>Expand your ambit and strengthen your profile by consulting patients globally</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="ln-specialFeature">
				<div className="uk-container">
					<div className="uk-grid">
						<div className="uk-width-1-2@s uk-flex-last@s uk-margin-small-bottom" uk-scrollspy="cls: uk-animation-slide-right uk-animation-fade; delay: 300; repeat: false">
							<img src="images/login/ai-system.png" />
						</div>
						<div className="uk-width-1-2@s" uk-scrollspy="cls: uk-animation-slide-left uk-animation-fade; delay: 300; repeat: false">
							<div className="ln-sectionTitle">
								<h2 className="ln-sectionTitle">Swasthi - Our state of the art AI System</h2>
								<h3>Assists you throughout your consultation journey with us</h3>
							</div>
							<ul>
								<li>
									<span uk-icon="check" />
									Matches your specialization with patient's requirements
								</li>
								<li>
									<span uk-icon="check" />
									Our bot collects basic patient information before you start the consultation, saving your time
								</li>
								<li>
									<span uk-icon="check" />
									Helps in digitally recording patient history thus making follow-ups effortless.
								</li>
								<li>
									<span uk-icon="check" />
									Enables you to take up consultations only when you are available
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>

			<section className="ln-featured easyStepFt">
				<div className="uk-container">
					<div className="ln-sectionTitle">
						<h2>
							Get Started in <span>3 Simple Steps</span>
						</h2>
					</div>
					<div className="uk-grid-column-collapse uk-grid">
						<div className="uk-width-expand@s ln-easyStepFt">
							<div className="ln-featuredBox" uk-scrollspy="cls: uk-animation-slide-right uk-animation-fade; delay: 300; repeat: false">
								<div className="ln-featureImgWrap">
									<img src="images/login/stp-register.png" />
								</div>
								<h5>SIGN UP</h5>
								<p>Fill in your basic information such as your name, DOB, Work Experience, Specialization etc.</p>
							</div>
						</div>
						<div className="uk-width-auto@s brandTextLightGray uk-text-center" uk-scrollspy="cls: uk-animation-slide-right uk-animation-fade; delay: 350; repeat: false">
							<i uk-icon="icon:chevron-right;ratio:4" className="uk-margin-medium-top uk-visible@s" />
							<i uk-icon="icon:chevron-down;ratio:4" className="uk-hidden@s" />
						</div>
						<div className="uk-width-expand@s ln-easyStepFt">
							<div className="ln-featuredBox" uk-scrollspy="cls: uk-animation-slide-right uk-animation-fade; delay: 400; repeat: false">
								<div className="ln-featureImgWrap">
									<img src="images/login/stp-verify.png" />
								</div>
								<h5>VERIFY</h5>
								<p>Upload your registration certificates, identity proof, and educational documents.</p>
							</div>
						</div>
						<div className="uk-width-auto@s brandTextLightGray uk-text-center" uk-scrollspy="cls: uk-animation-slide-right uk-animation-fade; delay: 350; repeat: false">
							<i uk-icon="icon:chevron-right;ratio:4" className="uk-margin-medium-top uk-visible@s" />
							<i uk-icon="icon:chevron-down;ratio:4" className="uk-hidden@s" />
						</div>
						<div className="uk-width-expand@s ln-easyStepFt">
							<div className="ln-featuredBox" uk-scrollspy="cls: uk-animation-slide-right uk-animation-fade; delay: 500; repeat: false">
								<div className="ln-featureImgWrap">
									<img src="images/login/stp-consult.png" />
								</div>
								<h5>CONSULT</h5>
								<p>After review and verification of your credentials by our team, you are all set to begin your online journey.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="ln-faq">
				<div className="uk-container" uk-scrollspy="cls: uk-animation-slide-bottom uk-animation-fade; delay: 300; repeat: false;offset:0">
					<div className="ln-sectionTitle">
						<h2>Frequently Asked Questions</h2>
					</div>
					<ul className="uk-accordion" uk-accordion="duration:300;transition:ease-out;offset:100px">
						<li>
							<a className="uk-accordion-title" href="#">
								How comfortable are patients with online consultation?
							</a>
							<div className="uk-accordion-content">
								<p>
									Given the fast-paced lifestyle, most people do not have time to visit the clinics physically. Our online consultation platform provides these patients early
									resolution of their health-related concerns thus saving time as well as providing them with much-needed privacy
								</p>
							</div>
						</li>
						<li>
							<a className="uk-accordion-title" href="#">
								Do I need to enrol with Healthmug on a full-time basis or can I work part-time?
							</a>
							<div className="uk-accordion-content">
								<p>
									There is no as such binding to be associated with Healthmug full-time to provide online consultations, you can consult patients anytime depending upon your schedule
									and availability.
								</p>
							</div>
						</li>
						<li>
							<a className="uk-accordion-title" href="#">
								How will patients connect with me?
							</a>
							<div className="uk-accordion-content">
								<p>Once patient signup for the consultation, our intelligent AI system Swasthi matches your specialization with patient's requirements.</p>
							</div>
						</li>
						<li>
							<a className="uk-accordion-title" href="#">
								How will I receive my earnings?
							</a>
							<div className="uk-accordion-content">
								<p>
									Once our executive gets in touch with you for verification, he/she will ask you to fill in your bank details in a form. after that is done, your earnings will be
									credited accordingly.
								</p>
							</div>
						</li>
						<li>
							<a className="uk-accordion-title" href="#">
								How will the online consultation process work?
							</a>
							<div className="uk-accordion-content">
								<div>Online consultation works in 5 easy steps:</div>
								<ul style={{ margin: "10px" }}>
									<li>
										Our bot Swasthi collects basic demographic information of the patient after he signs up, this form contains information like previous medical history, symptoms,
										allergies and other relevant details.
									</li>
									<li>Based on the patient's symptoms, our AI system assigns the patient to the relevant doctor.</li>
									<li>Once you are assigned the patient, his basic information form will be made available to you and your profile details would be shared with the patient.</li>
									<li>During the consultation, the patient is free to upload any medical reports or other required photos for better diagnosis. </li>
									<li>The system will enable you to generate an e-prescription which will be automatically recorded for further follow-ups.</li>
								</ul>
							</div>
						</li>
						<li>
							<a className="uk-accordion-title" href="#">
								How soon will my account get activated?
							</a>
							<div className="uk-accordion-content">
								<p>
									As soon as you upload your credentials for verification, our team will get in touch with you and once the process is complete your profile will be live for
									consultation on our portal within 24-48 hours.
								</p>
							</div>
						</li>
						<li>
							<a className="uk-accordion-title" href="#">
								What to do when I face technical issues or doubts?
							</a>
							<div className="uk-accordion-content">
								<p>
									If you face any technical problems while using your account, kindly write to us at doctor-support@healthmug.com with details and screenshots (if possible) of the
									issue you faced/facing. Our support team will answer the query at the earliest.
								</p>
							</div>
						</li>
						<li>
							<a className="uk-accordion-title" href="#">
								What are the rules/guidelines when consulting online?
							</a>
							<div className="uk-accordion-content">
								<p>The latest telemedicine rule will be applicable along with All MCI/AYUSH council laws apply, as applicable.</p>
							</div>
						</li>
					</ul>
				</div>
			</section>

			<section className="ln-specialFeature" style={{ backgroundColor: "transparent" }}>
				<div className="uk-container">
					<div className="uk-grid">
						<div className="uk-width-1-2@s" uk-scrollspy="cls: uk-animation-slide-left uk-animation-fade; delay: 400; repeat: false">
							<img src="images/login/grow-together.png" style={{ maxWidth: "450px" }} />
						</div>
						<div className="uk-width-1-2@s" uk-scrollspy="cls: uk-animation-slide-right uk-animation-fade; delay: 500; repeat: false">
							<div className="ln-sectionTitle">
								<h2 className="ln-sectionTitle">Sign Up</h2>
								<h3>Lets grow together</h3>
							</div>
							<ul>
								<li>
									<span uk-icon="check" />
									Reach new patients
								</li>
								<li>
									<span uk-icon="check" />
									Easy and secured payments
								</li>
								<li>
									<span uk-icon="check" />
									Manage your practice effortlessly
								</li>
								<li>
									<span uk-icon="check" />
									Interact with like minded professionals
								</li>
								<li>
									<span uk-icon="check" />
									Deliver smart patient care
								</li>
								<li>
									<span uk-icon="check" />
									Get High reimbursements on patient purchase
								</li>
							</ul>
							<div>
								<a href="/register" className="uk-button uk-button-small uk-button-secondary uk-width-1-3@s uk-width-1-1">
									Sign Up
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="testimonialsSliderWrap">
				<div className="uk-container" uk-scrollspy="cls: uk-animation-slide-left uk-animation-fade; delay: 500; repeat: false">
					<div className="ln-sectionTitle uk-text-center">
						<h2>Testimonials</h2>
						<h3>
							Read what other doctors says about <span className="brandTextPrimary">Healthmug</span>
						</h3>
					</div>
					<div uk-slider="center: true" className="testimonialsSlider">
						<ul className="uk-slider-items uk-child-width-1-1 uk-grid-column-collapse uk-margin-remove-top uk-grid" style={{ margin: "0px 40px" }}>
							<li className="testimonial">
								<h5>
									<svg>
										<use xlinkHref={ProjectUrl + "/images/sprite.svg#quote"}></use>
									</svg>
									<svg>
										<use xlinkHref={ProjectUrl + "/images/sprite.svg#quote"}></use>
									</svg>
									Healthmug is a very good platform for Doctors to connect with the patients. It has a simple and user-friendly interface which helps in patient-doctor interaction
									and results in proper diagnosis and treatment of the patient. I would recommend this platform to every doctor.
								</h5>
								<div className="testimonialBy">
									<img src="https://static.oxinis.com/healthmug/image/consultation/dr-shubham.jpg" />
									<div className="by">Dr. Shubham Dhingra</div>
									<div className="qualification">B.A.M.S</div>
								</div>
							</li>
							<li className="testimonial">
								<h5>
									<svg>
										<use xlinkHref={ProjectUrl + "/images/sprite.svg#quote"}></use>
									</svg>
									<svg>
										<use xlinkHref={ProjectUrl + "/images/sprite.svg#quote"}></use>
									</svg>
									Healthmug is a well-planned online consultation platform for the doctors to connect with their patients. It really helped me to manage all my consultations. I would
									recommend Healthmug Expert to every doctor as it is a great platform to start and grow your practice online.
								</h5>
								<div className="testimonialBy">
									<img src="https://static.oxinis.com/healthmug/image/consultation/dr-vaishali.jpg" />
									<div className="by">Dr. Vaishali Sharma</div>
									<div className="qualification">B.H.M.S and DNHE</div>
								</div>
							</li>
							<li className="testimonial">
								<h5>
									<svg>
										<use xlinkHref={ProjectUrl + "/images/sprite.svg#quote"}></use>
									</svg>
									<svg>
										<use xlinkHref={ProjectUrl + "/images/sprite.svg#quote"}></use>
									</svg>
									Online consultation is a way to bridge the gap between patients and doctors whenever needed, irrespective of time for non emergency treatments. As a Doctor it helps
									me to work at my pace and comfort of the patients.
									{/* It helps effectively to maintain a record of patients attended, at a go on my screen. On account
										of the Coronavirus Crisis, it has significantly helped me to monitor and assist the patients with my expertize. */}
								</h5>
								<div className="testimonialBy">
									<img src="https://static.oxinis.com/healthmug/image/consultation/dr-shruti.jpg" />
									<div className="by">Dr. Shruti Pednekar</div>
									<div className="qualification">B.H.M.S., M.D.</div>
								</div>
							</li>
						</ul>

						<a className="uk-position-top-left uk-position-small uk-hidden-hover brandTextDarkGray" href="#" uk-slidenav-previous="" uk-slider-item="previous" style={{ top: "10%" }}></a>
						<a className="uk-position-top-right uk-position-small uk-hidden-hover  brandTextDarkGray" href="#" uk-slidenav-next="" uk-slider-item="next" style={{ top: "10%" }}></a>
						<ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
					</div>
				</div>
			</section>

			<LoginModal />
			<ForgotPasswordModal />
		</>
	);
};

const LoginModal = () => (
	<div id="modalLogin" className="uk-flex-top" uk-modal="true" container="#pageRoot">
		<div className="uk-modal-dialog uk-margin-auto-vertical uk-modal-body">
			<button className="uk-modal-close-default" type="button" uk-close="true"></button>
			<h2>Login</h2>

			<div className="ln-loginMobile">
				<DoctorLogin />
			</div>
		</div>
	</div>
);

const ForgotPasswordModal = () => (
	<div id="modalForgotPwd" className="uk-flex-top" uk-modal="true" container="#pageRoot">
		<div className="uk-modal-dialog uk-margin-auto-vertical uk-modal-body uk-width-1-3 alertModal">
			<h3>Request to reset your password has been sent successfully to your registered mail id</h3>
			<div className="alertFooter">
				<button onClick={() => UIkit.modal("#modalForgotPwd").hide()} className="uk-button uk-button-secondary">
					Ok
				</button>
			</div>
		</div>
	</div>
);

export default LoginContent;
