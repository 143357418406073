import React from "react";
import { connect } from "react-redux";

import useLoader from "~/components/commonHooks/useLoader";

import Input from "~/components/common/input";

import { DoctorResetPassword, DoctorSetInputText, DoctorSetValidationError } from "~/actions/action-doctor";

import { ProjectUrl } from "~/env";

const ForgotPassword = (props) => {
	const [loader, startLoader, stopLoader] = useLoader(false);

	const handleInputTextChange = (e) => {
		props.Doctor_Set_Input_Text(e.target.name, e.target.value);
	};

	const handleEnter = (e) => {
		if (e.keyCode === 13) {
			handleResetPassword();
		}
	};

	const onFocusRemoveError = (e) => {
		if (!!props.errors[e.target.name]) {
			let errors = { ...props.errors };
			delete errors[e.target.name];
			props.Doctor_Set_Validation_Error(errors);
		}
	};

	const handleResetPassword = () => {
		startLoader();
		props
			.Doctor_Reset_Password()
			.then(() => {
				stopLoader();
				window.location = ProjectUrl;
			})
			.catch(() => stopLoader());
	};

	return (
		<div uk-height-viewport="true">
			<div className="smallForm">
				<div className="pageTitle">
					<h2>Reset Password</h2>
				</div>

				<Input
					onChange={handleInputTextChange}
					onFocus={onFocusRemoveError}
					type="email"
					name="email"
					value={props.email}
					label="Email"
					error={props.errors.email}
					onKeyUp={handleEnter}
					required
				/>
				<Input
					onChange={handleInputTextChange}
					onFocus={onFocusRemoveError}
					type="password"
					name="password"
					value={props.password}
					label="Password"
					error={props.errors.password}
					onKeyUp={handleEnter}
					required
				/>

				<Input
					onChange={handleInputTextChange}
					onFocus={onFocusRemoveError}
					type="password"
					name="confirm_password"
					value={props.confirm_password}
					label="Confirm Password"
					error={props.errors.confirm_password}
					onKeyUp={handleEnter}
					required
				/>
				<div className="brandBgLightest uk-padding-small">
					<ul style={{ margin: "0px" }}>
						<li>Password should have atleast 10 characters</li>
						<li>Password should contain atleast one capital letter, small letter, number and special character from #!@$&_~-</li>
					</ul>
				</div>
				<button onClick={handleResetPassword} disabled={loader} className="uk-button uk-button-secondary uk-margin-small-top uk-width-1-1">
					<span className="btnLoader">RESET PASSWORD {loader ? <div className="loader" uk-spinner="ratio: 0.5" /> : null}</span>
				</button>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	email: state.doctor_store.email,
	errors: state.doctor_store.errors,
	password: state.doctor_store.password,
	confirm_password: state.doctor_store.confirm_password,
});
const mapDispatchToProps = (dispatch) => ({
	Doctor_Reset_Password: () => dispatch(DoctorResetPassword()),
	Doctor_Set_Validation_Error: (errors) => dispatch(DoctorSetValidationError(errors)),
	Doctor_Set_Input_Text: (input_name, input_value) => dispatch(DoctorSetInputText(input_name, input_value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
