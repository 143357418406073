import React from "react";

import DoctorLogin from "~/components/login/doctor-login";

export default () => (
	<div className="headerLogin">
		<div className="headerWrap">
			<div className="uk-container">
				<div className="uk-grid-column-collapse uk-grid">
					<div className="uk-width-2-3 uk-width-2-5@s">
						<img src="images/logo.png" className="headerLogo" />
					</div>

					<div className="uk-width-3-5@s uk-visible@s">
						<div id="signIn" className="toggleLoginSignup">
							<DoctorLogin />
						</div>
						<div className="doctorLogin toggleLoginSignup hide" id="signUp" style={{ marginTop: "6px" }}>
							<span style={{ display: "inline-block", marginRight: "10px" }}>New user?</span>
							<a href="/register" className="uk-button uk-button-small uk-button-secondary">
								Sign Up
							</a>
						</div>
					</div>
					<div className="uk-width-1-3 uk-hidden@s alignRight" style={{ marginTop: "7px", textAlign: "right" }}>
						<button uk-toggle="target: #modalLogin" className="uk-button uk-button-small uk-button-secondary">
							Login
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
);
