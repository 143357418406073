import React from "react";
import { connect } from "react-redux";

import useLoader from "~/components/commonHooks/useLoader";

import Input from "~/components/common/input";

import { SupportLogin, DoctorSetInputText, DoctorSetValidationError } from "~/actions/action-doctor";

import { ProjectUrl } from "~/env";

const DoctorSupportLogin = (props) => {
	const [loader, startLoader, stopLoader] = useLoader(false);

	const handleInputTextChange = (e) => {
		props.Doctor_Set_Input_Text(e.target.name, e.target.value);
	};

	const handleEnter = (e) => {
		if (e.keyCode === 13) {
			handleSupportLogin();
		}
	};

	const onFocusRemoveError = (e) => {
		if (!!props.errors[e.target.name]) {
			let errors = { ...props.errors };
			delete errors[e.target.name];
			props.Doctor_Set_Validation_Error(errors);
		}
	};

	const handleSupportLogin = () => {
		startLoader();
		props
			.Support_Login()
			.then(() => {
				stopLoader();
				window.location = ProjectUrl + "/analytics/dashboard";
			})
			.catch(() => stopLoader());
	};

	return (
		<div uk-height-viewport="true">
			<div className="smallForm">
				<div className="pageTitle">
					<h2>Support Login</h2>
				</div>

				<Input
					onChange={handleInputTextChange}
					onFocus={onFocusRemoveError}
					onKeyPress={handleEnter}
					value={props.email}
					error={props.errors.email}
					type="email"
					name="email"
					label="Email"
					required
				/>
				<Input
					onChange={handleInputTextChange}
					onFocus={onFocusRemoveError}
					onKeyPress={handleEnter}
					value={props.support_code}
					error={props.errors.support_code}
					name="support_code"
					type="text"
					label="Support Code"
					required
				/>

				<button onClick={handleSupportLogin} disabled={loader} className="uk-button uk-button-secondary uk-margin-small-bottom uk-width-1-1">
					<span className="btnLoader">Login {loader ? <div className="loader" uk-spinner="ratio: 0.5" /> : null}</span>
				</button>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	email: state.doctor_store.email,
	errors: state.doctor_store.errors,
	support_code: state.doctor_store.support_code,
});
const mapDispatchToProps = (dispatch) => ({
	Support_Login: () => dispatch(SupportLogin()),
	Doctor_Set_Validation_Error: (errors) => dispatch(DoctorSetValidationError(errors)),
	Doctor_Set_Input_Text: (input_name, input_value) => dispatch(DoctorSetInputText(input_name, input_value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DoctorSupportLogin);
