import React from "react";
import thunk from "redux-thunk";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { combineReducers, createStore, applyMiddleware } from "redux";

import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

import "~/styles/general.scss";
import "~/styles/uikit-custom.scss";

import LoginScreen from "~/screens/login/login-screen";
import ForgotPasswordScreen from "~/screens/login/reset-password-screen";
import DoctorSupportLogin from "~/screens/login/support-login-screen";

import { DoctorReducer } from "~/reducers/doctor-reducer";

UIkit.use(Icons);

const App = () => {
	const pageType = () => {
		const page = window.location.pathname;
		if (page === "/") return <LoginScreen />;
		else if (page === "/doctor/support-login") return <DoctorSupportLogin />;
		else if (page === "/reset-password/" + window.__INITIAL_STATE__) return <ForgotPasswordScreen />;
	};

	return <Provider store={store}>{pageType()}</Provider>;
};

const reducers = combineReducers({
	doctor_store: DoctorReducer,
});

const store = createStore(reducers, applyMiddleware(thunk));

ReactDOM.render(<App />, document.getElementById("login"));
