import React from "react";

import Footer from "~/components/common/footer";
import LoginHeader from "~/components/login/login-header";
import LoginContent from "~/components/login/login-content";

const DoctorScreen = () => {
	return (
		<>
			<LoginHeader />
			<LoginContent />
			<Footer fat />
		</>
	);
};

export default DoctorScreen;
